import React, { useState, useEffect } from 'react';
import { rambleBlogsRef } from '../js/firebase';
import { set, onValue, push } from 'firebase/database';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function Blogswrite() {
  const [formData, setFormData] = useState({
    content: '',
    date: '',
    location: '',
    name: '',
  });

  useEffect(() => {
    onValue(rambleBlogsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        // If you don't use 'blogs', you can remove this part
        // setBlogs(Object.keys(data).map((key) => ({ id: key, ...data[key] })));
      } else {
        // If you don't use 'blogs', you can remove this part
        // setBlogs([]);
      }
    });
  }, []);

  const handleContentChange = (value) => {
    setFormData((prevData) => ({ ...prevData, content: value }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newBlogRef = push(rambleBlogsRef);
    await set(newBlogRef, {
      ...formData,
      timestamp: new Date().toString(),
    });
    setFormData({ content: '', date: '', location: '', name: '' });
  };

  return (
    <div>
      <div id="content-bottom-border" className="container">
  </div>

      <div className="container background-white" style={{ padding: '20px' }}>

        <div className="row">
          <div className="col-md-12 mx-auto">
            <br />
            <h2 className="mt-12 mb-12 text-center">Share Your Adventure</h2>
            
            
            
           <form
  onSubmit={handleSubmit}
  style={{ backgroundColor: '', padding: '20px', borderRadius: '10px' }}
>
  {['name', 'location'].map((field) => (
    <div key={field} className="mb-3">
      <label className="form-label">{field.charAt(0).toUpperCase() + field.slice(1)}</label>
      <input
        type="text"
        className="form-control"
        name={field}
        value={formData[field]}
        onChange={handleChange}
        required
      />
    </div>
  ))}
  <div className="mb-3">
    <label className="form-label">Date</label>
    <input
      type="date"
      className="form-control"
      name="date"
      value={formData.date}
      onChange={(e) => setFormData((prevData) => ({ ...prevData, date: e.target.value }))}
      required
    />
  </div>

  <div className="mb-3">
    <label className="form-label">Experiances</label>
    <ReactQuill
      value={formData.content}
      onChange={handleContentChange}
      modules={{
        toolbar: [
          [{ header: [1, 2, 3, false] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['link', 'image'],
          ['clean'],
        ],
      }}
      formats={[
        'header',
        'bold', 'italic', 'underline', 'strike',
        'list', 'bullet',
        'link', 'image',
      ]}
      style={{ height: '200px' }}  // Adjust the height as needed
      required
    />
  </div>

  
  

<br/>  <br/><br/>
<br/>  

  <p className="animate fadeInUp text-center">
  <button type="submit" className="button-9">
  Submit
</button>

  </p>
</form>






          </div>
        </div>
      </div>

      
    </div>
  );
}
