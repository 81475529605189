import Copyright from "../components/Copyright";
import CreateDarjeelingPackages from "../components/CreateDarjeelingPackages";
import DarjeelingPackages from "../components/DarjeelingPackages";
import Footer from "../components/Footer";
function Darjeeling() {
  return (
    <div className="Darjeeling">


<div id="post_header" className="container" style={{height: '240px'}}>
</div>


<div id="content">

  <DarjeelingPackages/>

</div>

<div id="pre-header" className="container" style={{height: '240px'}}>
</div>

<div id="content-bottom-border" className="container">
</div>
<CreateDarjeelingPackages/>


<div id="content-bottom-border" className="container">
</div>
<div id="pre-header" className="container" style={{height: '240px'}}>
</div>

<Footer/>



<Copyright/>


</div>






);
}
export default Darjeeling;