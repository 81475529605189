import React, { useState } from 'react';
import { FoodMenuRef } from '../js/firebase'; // Import Firebase references
import { set, push } from 'firebase/database';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function CreateFoodMenu() {
  const [formData, setFormData] = useState({
    itemCode: '',
    itemName: '',
    price: '',
    description: '',
  });
  const [error, setError] = useState('');
  const [showForm, setShowForm] = useState(false); // State to manage form visibility

  const handleContentChange = (value) => {
    setFormData((prevData) => ({ ...prevData, description: value }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const password = window.prompt("Please enter the password to submit the form:");
    
    if (password !== 'Nokia@1100') {
      setError('Invalid password. Please try again.');
      return;
    }

    const newItemRef = push(FoodMenuRef);
    const { itemCode, itemName, price, description } = formData;

    const dataToSubmit = {
      itemCode,
      itemName,
      price,
      description,
      timestamp: new Date().toString(),
    };

    await set(newItemRef, dataToSubmit);
    
    // Reset form
    setFormData({ itemCode: '', itemName: '', price: '', description: '' });
    setError('');
    setShowForm(false); // Hide the form after submission
  };

  const handleAddItemsClick = () => {
    const password = window.prompt("Please enter the password to view the form:");
    if (password === 'Nokia@1100') {
      setShowForm(true); // Show the form if password is correct
    } else {
      setError('Invalid password. Please try again.');
    }
  };

  return (
    <div>
      <div className="container background-white" style={{ padding: '20px' }}>
  <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
    <button onClick={handleAddItemsClick} className="category-button">
      Add Items in Menu
    </button>
  </div>

  {error && <div className="alert alert-danger">{error}</div>}

  {showForm && (
    <div className="row">
      <div className="col-md-12 mx-auto">
        <br />
        <h2 className="mt-12 mb-12 text-center">Submit Food Item</h2>
        <form onSubmit={handleSubmit} style={{ padding: '20px', borderRadius: '10px' }}>
                <div className="mb-3">
                  <label className="form-label">Item Code</label>
                  <input
                    className="form-control"
                    name="itemCode"
                    value={formData.itemCode}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="mb-3">
                  <label className="form-label">Item Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="itemName"
                    value={formData.itemName}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="mb-3">
                  <label className="form-label">Price in Rupees</label>
                  <input
                    type="number"
                    className="form-control"
                    name="price"
                    value={formData.price}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="mb-3">
                  <label className="form-label">Item Description</label>
                  <ReactQuill
                    value={formData.description}
                    onChange={handleContentChange}
                    modules={{
                      toolbar: [
                        [{ header: [1, 2, 3, false] }],
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        ['link', 'clean'],
                      ],
                    }}
                    formats={[
                      'header', 'bold', 'italic', 'underline', 'strike',
                      'list', 'bullet', 'link',
                    ]}
                    style={{ height: '200px' }}
                    required
                  />
                </div>

                <p className="animate fadeInUp text-center">
                  <button type="submit" className="button-9">
                    Submit
                  </button>
                </p>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
