import React, { useState } from 'react';
import { DJTPackagesRef } from '../js/firebase'; // Import Firebase references
import { set, push } from 'firebase/database'; // Remove onValue, as it's not needed
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function CreateDarjeelingTourPackages() {
  const [formData, setFormData] = useState({
    name: '',
    itinerary: '',
    package: '', // Added package field
  });
  const [error, setError] = useState('');

  const handleContentChange = (value) => {
    setFormData((prevData) => ({ ...prevData, itinerary: value }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const password = window.prompt("Please enter the password to submit the form:");
    
    if (password !== 'Nokia@1100') {
      setError('Invalid password. Please try again.');
      return;
    }

    const newBlogRef = push(DJTPackagesRef);
    await set(newBlogRef, {
      ...formData,
      timestamp: new Date().toString(),
    });
    
    setFormData({ name: '', itinerary: '', package: '' });
    setError('');
  };

  return (
    <div>
      <div className="container background-white" style={{ padding: '20px' }}>
        <div className="row">
          <div className="col-md-12 mx-auto">
            <br />
            <h2 className="mt-12 mb-12 text-center">Submit Darjeeling Tour Package</h2>
            {error && <div className="alert alert-danger">{error}</div>}
            <form
              onSubmit={handleSubmit}
              style={{ backgroundColor: '', padding: '20px', borderRadius: '10px' }}
            >
              <div className="mb-3">
                <label className="form-label">Package</label>
                <input
                  type="text"
                  className="form-control"
                  name="package"
                  value={formData.package}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Itinerary</label>
                <ReactQuill
                  value={formData.itinerary}
                  onChange={handleContentChange}
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, 3, false] }],
                      ['bold', 'italic', 'underline', 'strike'],
                      [{ list: 'ordered' }, { list: 'bullet' }],
                      ['link', 'image'],
                      ['clean'],
                    ],
                  }}
                  formats={[
                    'header',
                    'bold', 'italic', 'underline', 'strike',
                    'list', 'bullet',
                    'link', 'image',
                  ]}
                  style={{ height: '200px' }}
                  required
                />
              </div>

              <p className="animate fadeInUp text-center">
                <button type="submit" className="button-9">
                  Submit
                </button>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
