// src/ChatBot.jsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ChatBot.css';

const ChatBot = () => {
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([{ user: 'bot', text: 'Hello! How can I assist you today?' }]);
  const [config, setConfig] = useState({});
  const [loading, setLoading] = useState(false);

  // Load the config.json from Firebase Hosting
  useEffect(() => {
    const loadConfig = async () => {
      try {
        const response = await axios.get('https://rambletravels.web.app/config.json');
        setConfig(response.data);
      } catch (error) {
        console.error('Error loading config:', error);
      }
    };
    loadConfig();
  }, []);

  const sendMessage = async () => {
    if (input.trim() === '') return;

    const newMessage = { user: 'user', text: input };
    setMessages([...messages, newMessage]);
    setInput('');
    setLoading(true);

    try {
      // Call your hosted backend API
      const chatbotResponse = await axios.post('https://your-backend-url/respond', { message: input, config });

      const botMessage = { user: 'bot', text: chatbotResponse.data.response };
      setMessages((prevMessages) => [...prevMessages, botMessage]);
    } catch (error) {
      console.error('Error sending message:', error);
      const errorMessage = { user: 'bot', text: 'Sorry, I am having trouble responding right now.' };
      setMessages((prevMessages) => [...prevMessages, errorMessage]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="chatbot-container">
      <h2>ChatBot</h2>
      <div className="chatbot-messages">
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.user}`}>
            <strong>{message.user === 'bot' ? 'Bot' : 'You'}:</strong> {message.text}
          </div>
        ))}
        {loading && <div className="message bot"><strong>Bot:</strong> Typing...</div>}
      </div>
      <div className="chatbot-input">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type your message here..."
          onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
          disabled={loading}
        />
        <button onClick={sendMessage} disabled={loading}>Send</button>
      </div>
    </div>
  );
};

export default ChatBot;
