import React from 'react'
import Blogshow from '../components/Blogshow';
import Blogswrite from '../components/Blogswrite';
import Footer from '../components/Footer';
import Copyright from '../components/Copyright';

export default function Blogs() {
  return (
    <div>



  
  <div id="pre-header" className="container" style={{height: '240px'}}>
  </div>
  <div id="content-bottom-border" className="container">
  </div>
  
      
      <Blogshow/>

<div id="pre-header" className="container" style={{height: '240px'}}>
</div>



      <Blogswrite/>
      <div id="content-bottom-border" className="container">
</div>
<div id="pre-header" className="container" style={{height: '240px'}}>
</div>
<Footer/>
<Copyright/>


      </div>
  );
}
