import React from 'react'



export default function Hire() {

  const phoneNumber = '918509631121'; // Replace with your actual phone number
  const message = 'Interested'; // Replace with your desired message

  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

  return (
    <div>
        
        <div className="container background-white">
      <div className="row padding-vert-40">
        <div className="col-md-12">
          <h2 className="animate fadeIn text-center">WE ARE CURRENTLY HIRING!</h2>
          <p className="animate fadeIn text-center">If you like to work with a creative team in a lively and friendly enviroment then message us today!</p>
          
          
      <p className="animate fadeInUp text-center">
      <a href={whatsappLink} target="_blank" rel="noopener noreferrer">


      <button className="button-9">Apply Now</button>
      </a>

      
    </p>


        </div>
      </div>
    </div>
        
        </div>
  );
}
