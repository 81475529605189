import React, { useState, useEffect } from 'react';
import { onValue } from 'firebase/database';
import { FoodMenuRef } from '../js/firebase';
import '../css/foodmenu.css';

const categories = [
  { name: "All Items in Our Food-Menu", code: "" },
  { name: "Vegetarian Appetizers", code: "VA"},
  { name: "Main Vegetarian Dishes", code: "MV"},
  { name: "Egg Specialties", code: "ES"},
  { name: "Savory Chicken", code: "SM"},
  { name: "Mutton Selections", code: "MS"},
  { name: "Fish Delicacies", code: "FD"},
  { name: "Rice & Accompaniments", code: "RA"},
  { name: "Chapati (Roti) Varieties", code: "CV"},
  { name: "Thalis (Platter Meals)", code: "TH"},
  { name: "Delicious Desserts", code: "DD"},
  { name: "Refreshing Beverages", code: "RB"},
  { name: "Paratha Specials", code: "PS"},
  { name: "Chinese Delicacies", code: "CD"},
];

export default function FoodMenuItems() {
  const [packages, setPackages] = useState([]);
  const [visibleItems, setVisibleItems] = useState(null); // Track currently visible category

  useEffect(() => {
    const unsubscribe = onValue(FoodMenuRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const packagesArray = Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));
        setPackages(packagesArray);
      }
    });
    return () => unsubscribe();
  }, []);

  const toggleItems = (code, header) => {
    setVisibleItems(prev => (prev === header ? null : header)); // Toggle logic
  };

  return (
    <div className="package-container">
      <div className="category-buttons">
        {categories.map((category) => (
          <div key={category.code}>
            <button 
              className="button-33" 
              onClick={() => toggleItems(category.code, category.name)}
            >
              {category.name}
            </button>

            {visibleItems === category.name && (
              <div className="filtered-items">
                <h2 className="items-header">{category.name}</h2>
                {packages.filter(pkg => pkg.itemCode.startsWith(category.code)).map((pkg) => (
                  <div className="foodmenu__package-box" key={pkg.id}>
                    <img 
                      src={`assets/img/image/foodmenu/${pkg.itemCode}.jpg`} 
                      alt={pkg.itemName} 
                      className="foodmenu__image" 
                    />
                    <div className="foodmenu__info">
                      <span className="foodmenu__code">Item Code: {pkg.itemCode}</span>
                      <span className="foodmenu__name">Item Name: {pkg.itemName}</span>
                      <span className="foodmenu__description" dangerouslySetInnerHTML={{ __html: pkg.description }} />
                      <span className="foodmenu__price">Price: Rs {parseFloat(pkg.price).toLocaleString()} (INR)</span>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
