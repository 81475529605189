import React, { useState, useEffect } from 'react';
import { onValue } from 'firebase/database';
import { rambleBlogsRef } from '../js/firebase';

export default function Blogshow() {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const unsubscribe = onValue(rambleBlogsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setBlogs(Object.keys(data).map((key) => ({ id: key, ...data[key] })));
      } else {
        setBlogs([]);
      }
    });

    // Cleanup the subscription when the component unmounts
    return () => unsubscribe();
  }, []);

  const renderContent = (content) => {
    return { __html: content }; // Use the dangerouslySetInnerHTML attribute
  };

  return (
    <div>
      {blogs.map((blog, index) => (
        <React.Fragment key={blog.id}>
          <div id="content-bottom-border" className="container">
  </div>

          <div className="container background-white">
            <div className="row">
              <div className="col-md-12 mx-auto">
                <br />
                <h2 className="mt-12 mb-12 text-center">Recent Blogs</h2>

                <hr />
                <div>
                  <div style={{ textAlign: 'center' }}>
                    <p style={{ margin: '2px 0', display: 'inline-block', marginRight: '10px' }}>
                      <strong>Traveler's Name:</strong> {blog.name}
                    </p>
                    <p style={{ margin: '2px 0', display: 'inline-block', marginRight: '10px' }}>
                      <strong>Travel Date:</strong> {blog.date}
                    </p>
                    <p style={{ margin: '2px 0', display: 'inline-block', marginRight: '10px' }}>
                      <strong>Location:</strong> {blog.location}
                    </p>
                    <p style={{ margin: '2px 0' }}>
                      <strong>Experience:</strong> <span dangerouslySetInnerHTML={renderContent(blog.content)} />
                    </p>
                    <p style={{ margin: '2px 0' }}>
                      <small className="text-muted">{new Date(blog.timestamp).toLocaleString()}</small>
                    </p>
                  </div>
                </div>
                <hr />
              </div>
            </div>
          </div>
          <div id="content-bottom-border" className="container">
  </div>

          {index < blogs.length - 1 && (
            <div id="pre-header" className="container" style={{ height: '100px' }}></div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
}
