import Copyright from "../components/Copyright";
import CreateSikkimDis from "../components/CreateSikkimDis";
import SikkimDis from "../components/SikkimDis";
import Footer from "../components/Footer";
function DisSikkim() {
  return (
    <div className="DisSikkim">


<div id="post_header" className="container" style={{height: '240px'}}>
</div>


<div id="content">

  <SikkimDis/>

</div>

<div id="pre-header" className="container" style={{height: '240px'}}>
</div>

<div id="content-bottom-border" className="container">
</div>
<CreateSikkimDis/>


<div id="content-bottom-border" className="container">
</div>
<div id="pre-header" className="container" style={{height: '240px'}}>
</div>

<Footer/>



<Copyright/>


</div>






);
}
export default DisSikkim;