import Copyright from "../components/Copyright";
import CreateSikkimPackages from "../components/CreateSikkimPackages";
import SikkimPackages from "../components/SikkimPackages";
import Footer from "../components/Footer";








function Sikkim() {
  return (
    <div className="Sikkim">
      
      
          

<div id="post_header" className="container" style={{height: '240px'}}>
</div>



<div id="content">

  <SikkimPackages/>



</div>


<div id="pre-header" className="container" style={{height: '240px'}}>
</div>
<div id="content-bottom-border" className="container">
</div>

<CreateSikkimPackages/>




<div id="content-bottom-border" className="container">
</div>
<div id="pre-header" className="container" style={{height: '240px'}}>
</div>

<Footer/>



<Copyright/>


</div>






);
}
export default Sikkim;