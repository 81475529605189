import Copyright from "../components/Copyright";
import CreateKalimpongTourPackages from "../components/CreateKalimpongTourPackages";
import KalimpongTourPackages from "../components/KalimpongTourPackages";
import Footer from "../components/Footer";
function KalimpongTour() {
  return (
    <div className="KalimpongTour">


<div id="post_header" className="container" style={{height: '40px'}}>
</div>


<div id="content">

  <KalimpongTourPackages/>

</div>

<div id="pre-header" className="container" style={{height: '40px'}}>
</div>

<div id="content-bottom-border" className="container">
</div>
<CreateKalimpongTourPackages/>


<div id="content-bottom-border" className="container">
</div>
<div id="pre-header" className="container" style={{height: '40px'}}>
</div>

<Footer/>



<Copyright/>


</div>






);
}
export default KalimpongTour;