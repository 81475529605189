import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styled, { keyframes } from 'styled-components';

// Styled components
const SlideContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 1200px;  /* Set a max width for large screens */
  margin: 0 auto;  /* Center the container */
`;

const SlideImageContainer = styled.div`
  width: 100%;
  height: 400px; /* Default height for the slide box */
  position: relative;
  overflow: hidden; /* Ensures the image fits within the container */
  
  @media (max-width: 768px) {
    height: 250px; /* Smaller height for mobile */
  }
`;

const SlideImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;  /* Ensure images cover the area without distortion */
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const SlideTextContainer = styled.div`
  position: absolute;
  top: 10px;  /* Position the text at the top */
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  padding: 10px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  animation: ${fadeIn} 0.6s ease-in-out;
  z-index: 1;  /* Ensure this is above the button */

  @media (max-width: 768px) {
    padding: 5px;
    top: 15px;  /* Reduce the top spacing on mobile */
  }
`;

const SlideText = styled.h2`
  margin: 0;
  font-size: 1.5vw;  /* Use viewport width (vw) to make the text responsive */
  font-weight: bold;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);

  @media (max-width: 768px) {
    font-size: 3vw; /* Adjust text size for mobile */
  }
`;

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 10px;  /* Position the button at the bottom */
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  z-index: 2; /* Ensure the button is above the image */
`;

const Button = styled.a`
  background-color: #bebdd4;
  background-image: linear-gradient(#787679, #252329);
  border-radius: 23px;
  border: 0;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  font-family: 'Segoe UI', sans-serif;
  font-size: 16px;
  padding: 8px 20px;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.3s ease;
  text-decoration: none;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 4px 4px 8px;
    transform: scale(1.05);
  }
`;


export default function Slide2() {
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    // Array of travel quotes to display on each slide
    const travelQuotes = [
      "A journey is best measured in friends, rather than miles. – Tim Cahill",
"Adventure is worthwhile in itself. – Amelia Earhart",
"Adventure may hurt you, but monotony will kill you. ",
"Do not follow where the path may lead. Go instead where there is no path and leave a trail. – Ralph Waldo Emerson",
"I haven’t been everywhere, but it’s on my list. – Susan Sontag",
"I travel for travel’s sake. The great affair is to move. – Robert Louis Stevenson",
"I travel not to go anywhere, but to go. I travel for travel’s sake. The great affair is to move. – Robert Louis Stevenson",
"If you never go, you'll never know. ",
"If you think adventure is dangerous, try routine, it’s lethal. – Paulo Coelho",
"It feels good to be lost in the right direction. ",
"It’s not the destination, it’s the journey. – Ralph Waldo Emerson",
"It’s not what you look at that matters, it’s what you see. – Henry David Thoreau",
"Let’s find some beautiful place to get lost. ",
"Life begins at the end of your comfort zone. – Neale Donald Walsch",
"Life is either a daring adventure or nothing at all. – Helen Keller",
"Life is short, and the world is wide. – Simon Raven",
"Live your life by a compass, not a clock. – Stephen Covey",
"Not all those who wander are lost, but all who travel are found. ",
"Not all those who wander are lost, but those who travel in search of their passion are never lost. ",
"Not all those who wander are lost. – J.R.R. Tolkien",
"Oh, the places you’ll go. – Dr. Seuss",
"Once a year, go someplace you’ve never been before. – Dalai Lama",
"People don’t take trips, trips take people. – John Steinbeck",
"Take only memories, leave only footprints. – Chief Seattle",
"Take only pictures, leave only footprints. ",
"Take the scenic route. ",
"The best stories are found between the pages of a passport. ",
"The best view comes after the hardest climb. ",
"The earth has music for those who listen. – George Santayana",
"The journey of a thousand miles begins with one step. – Lao Tzu",
"The journey, not the arrival, matters. – T.S. Eliot",
"The life you have led doesn’t need to be the only life you have. – Anna Quindlen",
"The more I traveled, the more I realized that fear makes strangers of people who should be friends. – Shirley MacLaine",
"The road is life. – Jack Kerouac",
"The world is a book, and those who do not travel read only one page. – Saint Augustine",
"The world is a playground, and life is pushing my swing. – Natalie Kocsis",
"The world is too big to stay in one place and too small to not visit. ",
"The world is too big to stay in one place, and life is too short to do just one thing. ",
"There are no strangers here; only friends you haven’t met. – William Butler Yeats",
"To awaken quite alone in a strange town is one of the most pleasant sensations in the world. – Freya Stark",
"To travel is to live. – Hans Christian Andersen",
"To travel is to take a journey into yourself. – Danny Kaye",
"Travel brings power and love back into your life. – Rumi",
"Travel far enough, you meet yourself. – David Mitchell",
"Travel far enough, you meet yourself. – David Mitchell",
"Travel is an investment in yourself. – Matthew Karsten",
"Travel is never a matter of money but of courage. – Paulo Coelho",
"Travel is the healthiest addiction. ",
"Travel is the only thing you buy that makes you richer. ",
"Travel is the only thing you buy that makes you richer. ",
"Travel makes one modest. You see what a tiny place you occupy in the world. – Gustav Flaubert",
"Travel, in the younger sort, is a part of education; in the elder, a part of experience. – Francis Bacon",
"Traveling – it leaves you speechless, then turns you into a storyteller. – Ibn Battuta",
"Traveling is like flirting with life. It’s like saying, ‘I would stay and love you, but I have to go; this is my station.’ – Lisa St. Aubin de Terán",
"Traveling opens your heart, broadens your mind, and fills your life with stories to tell. – Paula Bendfeldt",
"Two roads diverged in a wood, and I— I took the one less traveled by. – Robert Frost",
"Wander often, wonder always. ",
"We travel not to escape life, but for life not to escape us. – Anonymous",
"We travel not to escape life, but for life not to escape us. ",
"We wander for distraction, but we travel for fulfillment. – Hilaire Belloc",
"Wherever you go becomes a part of you somehow. – Anita Desai",
"Wherever you go becomes a part of you somehow. – Anonymous",
"You can’t understand a city without using its public transportation system. – Erol Ozan",
"You don’t have to be rich to travel well. – Eugene Fodor",
"You have to go on and be crazy. Craziness is like heaven. – Jimi Hendrix",


      
    ];

    // Manually specify the image names in the public/assets/Images/ folder
    const imageNames = [
      'Darjeeling tourism.jpg',
'Best hotels in Darjeeling.jpg',
'Darjeeling tea gardens.jpg',
'Darjeeling Himalayan railway.jpg',
'Darjeeling sightseeing places.jpg',
'Darjeeling winter snowfall.jpg',
'Sikkim tourism.jpg',
'Sikkim travel guide.jpg',
'North Sikkim snowfall.jpg',
'Sikkim adventure tours.jpg',
'Gangtok hotels and resorts.jpg',
'Gangtok travel guide.jpg',
'Things to do in Gangtok.jpg',
'Gangtok sightseeing.jpg',
'Gangtok nightlife.jpg',
'Gangtok shopping spots.jpg',
'Best hotels in Gangtok.jpg',
'Sikkim honeymoon package.jpg',
'Sikkim trekking routes.jpg',
'Sikkim homestays.jpg',
'Pelling travel guide.jpg',
'Pelling sightseeing places.jpg',
'Best hotels in Pelling.jpg',
'Pelling monasteries.jpg',
'Pelling adventure activities.jpg',
'Namchi tourism.jpg',
'Things to do in Namchi.jpg',
'Namchi sightseeing.jpg',
'Namchi monastery.jpg',
'Best hotels in Namchi.jpg',
'North Sikkim tourism.jpg',
'Snowfall in North Sikkim.jpg',
'Lachung hotels.jpg',
'Lachen travel guide.jpg',
'North Sikkim homestays.jpg',
'Yumthang Valley Sikkim.jpg',
'Zero Point North Sikkim.jpg',
'Sikkim snowfall season.jpg',
'Best time to visit Sikkim.jpg',
'Sikkim tour packages.jpg',
'Darjeeling toy train.jpg',
'Sikkim hot springs.jpg',
'Snowfall in Gangtok.jpg',
'Sikkim budget hotels.jpg',
'North Sikkim adventure.jpg',
'Sikkim monastery tours.jpg',
'Kanchenjunga view from Sikkim.jpg',
'Gangtok MG Marg.jpg',
'Sikkim cultural tour.jpg',
'Best cafes in Gangtok.jpg',
'Pelling trekking trails.jpg',
'Sikkim flower festival.jpg',
'Sikkim homestay experience.jpg',
'Tsomgo Lake Gangtok.jpg',
'Gangtok ropeway.jpg',
'Darjeeling Mall Road.jpg',
'Darjeeling sunrise at Tiger Hill.jpg',
'Rumtek Monastery Gangtok.jpg',
'Gangtok budget hotels.jpg',
'Darjeeling heritage hotels.jpg',
'Gangtok snowfall experience.jpg',
'Zuluk Sikkim.jpg',
'Pelling luxury resorts.jpg',
'Sikkim solo travel.jpg',
'Sikkim family packages.jpg',

      // You can add more images here as needed
    ];

    // Map the image paths and quotes to create slides
    const slidesData = imageNames.map((imageName, index) => ({
      src: `/assets/Images/${encodeURIComponent(imageName)}`, // Ensure proper encoding for spaces or special chars
      text: travelQuotes[index % travelQuotes.length], // Use the quotes for text (cycling through the quotes)
    }));

    setSlides(slidesData);
  }, []);

  const settings = {
    dots: false, // Disable dots
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000, // Adjust autoplay speed (in milliseconds)
    pauseOnHover: true,  // Pause autoplay on hover for better user experience
    arrows: false, // Hide navigation arrows
  };

  return (
    <SlideContainer>
      <div className="container no-padding background-gray-lighter">
        <Slider {...settings}>
          {slides.map((slide, index) => (
            <div key={index}>
              <SlideImageContainer>
                <SlideImage src={slide.src} alt={`Slide ${index + 1}`} />
                <SlideTextContainer>
                  <SlideText>{slide.text}</SlideText>
                </SlideTextContainer>
                <ButtonContainer>
                  <Button href={`https://wa.me/918509631121?text=${encodeURIComponent(slide.text)}`}>
                    Details
                  </Button>
                </ButtonContainer>
              </SlideImageContainer>
            </div>
          ))}
        </Slider>
      </div>
    </SlideContainer>
  );
}
