import Copyright from "../components/Copyright";
import FoodMenuItems from "../components/FoodMenuItems";
import Footer from "../components/Footer";
import CreateFoodMenu from "../components/CreateFoodMenu";

function FoodMenu() {
  return (
    <div className="FoodMenu">
      <div className="sticky-header" style={{
    position: 'fixed',
    top: '100px',
    width: '100%',
    backgroundColor: 'rgba(51, 51, 51, 0.85)', // Slightly transparent background
    textAlign: 'center',
    zIndex: 1000,
    padding: '10px 0', // Add some padding for better spacing
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)', // Subtle shadow for depth
    borderRadius: '5px', // Rounded corners
}}>
  <h1 style={{
      color: '#FFFFFF',
      fontWeight: 'bold',
      margin: '0', // Remove default margin for better alignment
      textTransform: 'uppercase', // Uppercase for styling
  }}>
    Food & Drinks Menu
  </h1>
</div>


      <div id="content" style={{ marginTop: '0px', display: 'flex', justifyContent: 'center' }}> {/* Increased margin for sticky header */}
        <FoodMenuItems />
      </div>

      <div id="pre-header" className="container" style={{ height: '40px' }}>
      </div>

      <div id="content-bottom-border" className="container">
      </div>
      
      <CreateFoodMenu />

      <div id="content-bottom-border" className="container">
      </div>
      
      <div id="pre-header" className="container" style={{ height: '40px' }}>
      </div>

      <Footer />
      <Copyright />
    </div>
  );
}

export default FoodMenu;
