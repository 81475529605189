import React, { useState, useEffect } from 'react';
import { onValue } from 'firebase/database';
import { SKTPackagesRef } from '../js/firebase';

export default function SikkimTourPackages() {
  const [packages, setPackages] = useState([]);
  const [expandedPackageId, setExpandedPackageId] = useState(null);
  const [imageSets, setImageSets] = useState([]);
  const [activeImageIndices, setActiveImageIndices] = useState([]);

  const loadImages = () => {
    const imgArray = [];
    for (let i = 1; i <= 114; i++) {
      imgArray.push(`assets/img/frontpage/image${i}.jpg`);
    }
    return imgArray;
  };

  const assignImagesToPackages = (images) => {
    const shuffledImages = images.sort(() => Math.random() - 0.5);
    const sets = [];
    while (shuffledImages.length > 0) {
      sets.push(shuffledImages.splice(0, 3));
    }
    return sets;
  };

  useEffect(() => {
    const images = loadImages();
    const sets = assignImagesToPackages(images);
    setImageSets(sets.filter(set => set.length > 0).slice(0, packages.length));
    setActiveImageIndices(new Array(packages.length).fill(0));
  }, [packages]);

  useEffect(() => {
    const unsubscribe = onValue(SKTPackagesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const packagesArray = Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));
        setPackages(packagesArray);
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const intervals = packages.map((_, index) => {
      return setInterval(() => {
        setActiveImageIndices((prevIndices) => {
          const newIndices = [...prevIndices];
          newIndices[index] = (newIndices[index] + 1) % (imageSets[index]?.length || 1);
          return newIndices;
        });
      }, 3000);
    });

    return () => {
      intervals.forEach((id) => clearInterval(id));
    };
  }, [packages, imageSets]);

  const handleDetailsClick = (packageId) => {
    setExpandedPackageId(expandedPackageId === packageId ? null : packageId);
  };

  const handleBookClick = (packageName) => {
    const message = encodeURIComponent(`Interested in ${packageName} package`);
    window.open(`https://wa.me/918509631121?text=${message}`, '_blank');
  };

  return (
    <div className="package-container">
      {packages.map((pkg) => (
        <div className={`package-box ${expandedPackageId === pkg.id ? 'expanded' : ''}`} key={pkg.id}>
          <div className="image-slideshow">
            {imageSets[packages.indexOf(pkg)]?.map((image, imgIndex) => (
              <img
                key={imgIndex}
                src={image}
                alt={pkg.package} // Use only the package name for alt text
                className={`package-image ${activeImageIndices[packages.indexOf(pkg)] === imgIndex ? 'active' : ''}`}
                onError={(e) => { e.target.style.display = 'none'; }}
              />
            ))}
          </div>
  
          <div className="package-info">
            <h3>{pkg.package}</h3>
            {expandedPackageId !== pkg.id && (
              <button className="button-9" onClick={() => handleDetailsClick(pkg.id)}>
                <span className="text">Details</span>
              </button>
            )}
          </div>
  
          {expandedPackageId === pkg.id && (
            <div className="package-details">
              <p dangerouslySetInnerHTML={{ __html: pkg.itinerary }} />
              <div className="button-container">
                <button className="button-9" onClick={() => setExpandedPackageId(null)}>
                  Close
                </button>
                <button className="button-9" onClick={() => handleBookClick(pkg.package)}>
                  Book
                </button>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  ); 
}
