import React, { useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styled, { keyframes } from 'styled-components';

// Styled components
const SlideContainer = styled.div`
  position: relative;
`;

const SlideImageContainer = styled.div`
  width: 100%;
  height: auto;
  position: relative;
`;

const SlideImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Adjusted styled components
const SlideTextContainer = styled.div`
  position: absolute;
  bottom: 100px; // Default position above the button
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  padding: 10px; // Adjust padding for mobile
  background: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  animation: ${fadeIn} 0.6s ease-in-out;

  @media (max-width: 768px) {
    padding: 5px;
    bottom: 50px; // Adjust this value to move the text lower
    white-space: nowrap; // Prevent text from wrapping
  }
`;


const SlideText = styled.h2`
  margin: 0;
  font-size: 2.5em;
  font-weight: bold;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);

  @media (max-width: 768px) {
    font-size: 1.5em;
  }
`;

const SlidePrice = styled.p`
  margin: 0;
  font-size: 1.5em;
  font-weight: normal;
  color: #ffd700;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);

  @media (max-width: 768px) {
    font-size: 1.2em;
  }
`;

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 5px; // Keep this as is for button positioning
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
`;


const Button = styled.a`
  background-color: #bebdd4;
  background-image: linear-gradient(#787679, #252329);
  border-radius: 23px;
  border: 0;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  font-family: 'Segoe UI', sans-serif;
  font-size: 16px;
  padding: 8px 20px;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.3s ease;
  text-decoration: none; // Remove underline

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 4px 4px 8px;
    transform: scale(1.05);
  }
`;

export default function Slide() {
  const slides = [
    { src: 'assets/img/slideshow/slide1.jpg', text: 'Darjeeling Tour Package', price: 'Starting from Rs 2999' },
    { src: 'assets/img/slideshow/slide2.jpg', text: 'Gangtok Tour Package', price: 'Starting from Rs 3499' },
    { src: 'assets/img/slideshow/slide3.jpg', text: 'Namchi Tour Package', price: 'Starting from Rs 2999' },
    { src: 'assets/img/slideshow/slide4.jpg', text: 'Pelling Tour Package', price: 'Starting from Rs 3999' },
    { src: 'assets/img/slideshow/slide5.jpg', text: 'Lachen Tour Package', price: 'Starting from Rs 4499' },
    { src: 'assets/img/slideshow/slide6.jpg', text: 'Lachung Tour Package', price: 'Starting from Rs 4599' },
    { src: 'assets/img/slideshow/slide7.jpg', text: 'Kalimpong Tour Package', price: 'Starting from Rs 2999' },
    { src: 'assets/img/slideshow/slide8.jpg', text: 'Dwoors Tour Package', price: 'Starting from Rs 1999' },
    { src: 'assets/img/slideshow/slide9.jpg', text: 'Bhutan Tour Package', price: 'Starting from Rs 5999' },
    { src: 'assets/img/slideshow/slide10.jpg', text: 'Nepal Tour Package', price: 'Starting from Rs 6999' },
    { src: 'assets/img/slideshow/slide11.jpg', text: 'Darjeeling Tour Package', price: 'Starting from Rs 2999' },
    { src: 'assets/img/slideshow/slide12.jpg', text: 'Gangtok Tour Package', price: 'Starting from Rs 3499' },
    { src: 'assets/img/slideshow/slide13.jpg', text: 'Namchi Tour Package', price: 'Starting from Rs 2999' },
    { src: 'assets/img/slideshow/slide14.jpg', text: 'Pelling Tour Package', price: 'Starting from Rs 3999' },
    { src: 'assets/img/slideshow/slide15.jpg', text: 'Lachen Tour Package', price: 'Starting from Rs 4499' },
    { src: 'assets/img/slideshow/slide16.jpg', text: 'Lachung Tour Package', price: 'Starting from Rs 4599' },
    { src: 'assets/img/slideshow/slide17.jpg', text: 'Kalimpong Tour Package', price: 'Starting from Rs 2999' },
    { src: 'assets/img/slideshow/slide18.jpg', text: 'Dwoors Tour Package', price: 'Starting from Rs 1999' },

    // Add more slides as needed...
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Logic for changing slides (if needed)
    }, 2000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <SlideContainer>
      <div className="container no-padding background-gray-lighter">
        <Slider {...settings}>
          {slides.map((slide, index) => (
            <div key={index}>
              <SlideImageContainer>
                <SlideImage src={slide.src} alt={`Slide ${index + 1}`} />
                <SlideTextContainer>
                  <SlideText>{slide.text}</SlideText>
                  <SlidePrice>{slide.price}</SlidePrice>
                </SlideTextContainer>
                <ButtonContainer>
                  <Button href={`https://wa.me/918509631121?text=${encodeURIComponent(slide.text)}`}>
                    Book Now
                  </Button>
                </ButtonContainer>
              </SlideImageContainer>
            </div>
          ))}
        </Slider>
      </div>
    </SlideContainer>
  );
}
