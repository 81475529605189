import Copyright from "../components/Copyright";
import CreateOwnTourPackages from "../components/CreateOwnTourPackages";
import OwnTourPackages from "../components/OwnTourPackages";
import Footer from "../components/Footer";
function Own() {
  return (
    <div className="Own">


<div id="post_header" className="container" style={{height: '240px'}}>
</div>


<div id="content">

  <OwnTourPackages/>

</div>

<div id="pre-header" className="container" style={{height: '240px'}}>
</div>

<div id="content-bottom-border" className="container">
</div>
<CreateOwnTourPackages/>


<div id="content-bottom-border" className="container">
</div>
<div id="pre-header" className="container" style={{height: '240px'}}>
</div>

<Footer/>



<Copyright/>


</div>






);
}
export default Own;