import React, { useState, useEffect } from 'react';

export default function Copyright() {
  const [slideLeft, setSlideLeft] = useState(true);
  
  useEffect(() => {
  
  
    // Store the current time as the last visit
    const now = new Date();
    localStorage.setItem('lastVisit', now);

    const interval = setInterval(() => {
      setSlideLeft((prevSlideLeft) => !prevSlideLeft);
    }, 3000); // Change the duration as needed (currently set to 3 seconds)

    return () => clearInterval(interval);
  }, []);

  const slideStyle = {
    color: 'black',
    transition: 'transform 1s ease-in-out',
    transform: slideLeft ? 'translateX(-50%)' : 'translateX(0)',
  };

  return (
    <div>
      <div id="footer">
        <div className="container">
          <div className="row">
            <div id="footermenu" className="col-md-8">
              <ul className="list-unstyled list-inline">
                {/* <li>
                  <a href="/" target="_blank">Sample Link</a>
                </li>
                <li>
                  <a href="/" target="_blank">Sample Link</a>
                </li> */}
              </ul>
            </div>

            <div id="copyright" className="col-md-4" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <p style={{
                ...slideStyle,
                color: 'white',
                fontWeight: 'bold',
                marginRight: '15px'  // Adjust space between copyright and visit count
              }}>
                <strong>&copy; 2019 Ramble Networks</strong>
              </p>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
