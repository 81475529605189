import React from 'react';

export default function Main() {
  return (
    <div>
      <div className="container background-white">
        <div className="row margin-vert-30">
          {/* Main Text */}
          <div className="col-md-12">
            <h2 className="text-center mb-4 display-4">Welcome to RambleNetworks</h2>
            
            <p className="text-center">
              Your gateway to unforgettable travel experiences!<br />
              Located just a stone's throw away from NJP Railway Station in Jalpaiguri and a short 15 km drive from Bagdogra Airport, we are more than just a travel agency – we are your partners in exploring the most spectacular destinations across North East India and beyond.<br />
              Whether you're dreaming of the misty hills of Darjeeling, the majestic landscapes of Sikkim, the serene beauty of the Dooars, or the cultural richness of Nepal and Bhutan, RambleNetworks offers tailored travel experiences to fulfill all your wanderlust desires.<br />
              Our offerings include heart-pumping River Rafting, nostalgic Toy Train Joyrides, thrilling Jungle Safaris, mesmerizing Night Escapades, adrenaline-pumping Chopper Thrills, exhilarating Paragliding adventures, and immersive Trekking Expeditions through some of the most pristine environments on Earth.
            </p>
            <p className="text-center">
              At RambleNetworks, we believe that travel is not just about visiting places – it’s about creating lasting memories, weaving epic stories, and embarking on a journey where every moment is packed with joy, excitement, and discovery.<br />
              From luxury tours to budget-friendly trips, we customize every package to suit your needs, preferences, and desires, ensuring you have a trip that’s uniquely yours.<br />
              Whether you're a solo traveler, a couple, or a group of adventurers, we are dedicated to offering personalized service and expert guidance every step of the way.<br />
              Let us help you discover hidden gems, experience authentic local cultures, and explore off-the-beaten-path destinations that will leave you in awe.<br />
              RambleNetworks – Where every journey is a celebration of life and adventure.
            </p>

            {/* WhatsApp Contact Button */}
            <div className="text-center mt-4">
              <a href="https://wa.me/918509631121" target="_blank" rel="noopener noreferrer">
                <button className="button-9">Start Your Adventure</button>
              </a>
            </div>
            {/* End WhatsApp Contact Button */}
            
          </div>
          {/* End Main Text */}
        </div>
      </div>
    </div>
  );
}
